import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/turboviewer/turboviewer/apps/landing-page/src/app/context/toast-context.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/turboviewer/turboviewer/apps/landing-page/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/turboviewer/turboviewer/apps/landing-page/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/turboviewer/turboviewer/apps/landing-page/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/turboviewer/turboviewer/apps/landing-page/src/components/ScrollToTop/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/turboviewer/turboviewer/node_modules/.pnpm/next-intl@3.19.0_next@14.2.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18_du36jhxxocldyw7gfqeu5exjri/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/runner/work/turboviewer/turboviewer/node_modules/.pnpm/next-themes@0.3.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/turboviewer/turboviewer/node_modules/.pnpm/next@14.2.7_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.8/node_modules/next/font/google/target.css?{\"path\":\"src/app/(site)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
